import { render, staticRenderFns } from "./Veterinarian.vue?vue&type=template&id=7f82a41a&scoped=true&"
import script from "./Veterinarian.vue?vue&type=script&lang=ts&"
export * from "./Veterinarian.vue?vue&type=script&lang=ts&"
import style0 from "./Veterinarian.vue?vue&type=style&index=0&id=7f82a41a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.85.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f82a41a",
  null
  
)

export default component.exports